import React, { useEffect } from 'react'

import downloadImg from '../images/drag&drop_download.svg'
import deleteImg from '../images/drag&drop_delete.svg'
import arrowImg from '../images/full-arrow.svg'
import closeBtnImg from '../images/close-btn.svg'

function ViewPopup(props) {
    useEffect(() => {
        const handleKeyDown = (event) => {
          // Check if the pressed key is the left arrow (keyCode 37) or right arrow (keyCode 39)
          if (props.trigger && !props.deleterPopup) {
            if (event.keyCode === 37) {
                handleLeftArrowPress();
            } else if (event.keyCode === 39) {
                handleRightArrowPress();
            }
          }
        };
    
        // Attach the event listener when the component mounts
        document.addEventListener('keydown', handleKeyDown);
    
        // Detach the event listener when the component unmounts
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
    }, [props.trigger, props.deleterPopup, props.selectedImageId, props.selectedPromptId]);

    const handleDownload = () => {
        fetch('https://www.dandycv.com/media/' + props.data[props.selectedPromptId].image_name_list[props.selectedImageId].image_name)
        .then((response) => response.blob())
        .then((blob) => {
            // Create a Blob object and generate a URL for it
            const url = window.URL.createObjectURL(blob);

            // Create an anchor element for downloading
            const a = document.createElement('a');
            a.href = url;
            a.download = props.data[props.selectedPromptId].prompt + '.jpg'; // Specify the desired file name
            document.body.appendChild(a);

            // Trigger a click event on the anchor element to initiate the download
            a.click();

            // Remove the anchor element from the DOM
            document.body.removeChild(a);
        });
    }

    const handleLeftArrowPress = () => {
        if (props.selectedImageId > 0) {
            props.setSelectedImageId(props.selectedImageId - 1)
        }
        else if (props.selectedImageId === 0 && props.selectedPromptId > 0) {
            props.setSelectedPromptId(props.selectedPromptId - 1)
            props.setSelectedImageId(props.data[props.selectedPromptId-1].image_name_list.length - 1)
        }
    }

    const handleRightArrowPress = () => {
        // props.selectedImageId !== props.data[props.selectedPromptId].image_name_list.length - 1
        if (props.selectedImageId < props.data[props.selectedPromptId].image_name_list.length - 1) {
            props.setSelectedImageId(props.selectedImageId + 1)
        }
        else if ((props.selectedImageId === props.data[props.selectedPromptId].image_name_list.length - 1) && props.selectedPromptId < props.data.length - 1) {
            props.setSelectedPromptId(props.selectedPromptId + 1)
            props.setSelectedImageId(0)
        }
    }

    return (props.trigger) ? (
        <div className='popup-color-picker-overlay' onClick={() => props.setTrigger(false)}>
                <div className='popup-generate-image-view-content' onClick={(e) => {e.stopPropagation()}}>
                    <div style={{marginLeft: 'auto', marginRight: '20px', marginTop: '11px'}}>
                        <img src={closeBtnImg} alt='' style={{cursor: 'pointer'}} onClick={() => props.setTrigger(false)}/>
                    </div>

                    <div className='popup-generate-image-view-content-helper'>
                        <div className='popup-generate-image-view-content-left'>
                            <div className='popup-generate-image-view-content-left-container'>
                                <div className='popup-generate-image-view-content-left-title'>
                                    Model
                                </div>
                                <div className='popup-generate-image-view-content-left-body'>
                                    {props.data[props.selectedPromptId].model_type}
                                </div>
                                <div className='hr'></div>


                                <div className='popup-generate-image-view-content-left-title'>
                                    Date
                                </div>
                                <div className='popup-generate-image-view-content-left-body'>
                                    {props.data[props.selectedPromptId].date.slice(0, 19).replace('T', ' ')}
                                </div>
                                <div className='hr'></div>

                                {!props.upscaling && 
                                    <>
                                        {props.multiPrompting ? (
                                            props.data[props.selectedPromptId].prompt.map((item, idx) => (
                                                <>
                                                    <div className='popup-generate-image-view-content-left-title'>
                                                        prompt {idx + 1}
                                                    </div>
                                                    <div className='popup-generate-image-view-content-left-body'>
                                                        {item.prompt.length > 100 ? item.prompt.substring(0, 100) + '...' : item.prompt}
                                                    </div>

                                                    <div className='popup-generate-image-view-content-left-title'>
                                                        weight
                                                    </div>
                                                    <div className='popup-generate-image-view-content-left-body'>
                                                        {item.weight}
                                                    </div>
                                                    <div className='hr'></div>
                                                </>
                                            ))
                                        ) : (
                                            <>
                                                <div className='popup-generate-image-view-content-left-title'>
                                                    Prompt
                                                </div>
                                                <div className='popup-generate-image-view-content-left-body'>
                                                    {props.data[props.selectedPromptId].prompt.length > 100 ? props.data[props.selectedPromptId].prompt.substring(0, 100) + '...' : props.data[props.selectedPromptId].prompt}
                                                </div>
                                                <div className='hr'></div>

                                                <div className='popup-generate-image-view-content-left-title'>
                                                    Negative prompt
                                                </div>
                                                <div className='popup-generate-image-view-content-left-body'>
                                                    {props.data[props.selectedPromptId].negative_prompt ? props.data[props.selectedPromptId].negative_prompt : 'None'}
                                                </div>
                                                <div className='hr'></div>
                                            </>
                                        )}

                                        <div className='popup-generate-image-view-content-left-title'>
                                            Style
                                        </div>
                                        <div className='popup-generate-image-view-content-left-body'>
                                            {props.data[props.selectedPromptId].style ? props.data[props.selectedPromptId].style : 'No style'}
                                        </div>
                                        <div className='hr'></div>
                                    </>
                                }
                                

                                <div style={{display: 'flex'}}>
                                    {(!props.img2img || !props.upscaling) &&
                                        <div>
                                            <div className='popup-generate-image-view-content-left-title'>
                                                Ratio
                                            </div>
                                            <div className='popup-generate-image-view-content-left-body'>
                                                {props.data[props.selectedPromptId].ratio ? props.data[props.selectedPromptId].ratio : 'None'}
                                            </div>
                                        </div>
                                    }

                                    <div style={{marginLeft: (!props.img2img || !props.upscaling) && '20px'}}>
                                        <div className='popup-generate-image-view-content-left-title'>
                                            Size
                                        </div>
                                        <div className='popup-generate-image-view-content-left-body'>
                                            {props.upscaling ? (
                                                props.data[props.selectedPromptId].image_name_list[props.selectedImageId].image_resolution
                                            ) : (
                                                props.data[props.selectedPromptId].resolution ? props.data[props.selectedPromptId].resolution : 'None'
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='hr'></div>

                                {props.img2img && (
                                    <>
                                        <div className='popup-generate-image-view-content-left-title'>
                                            Strength
                                        </div>
                                        <div className='popup-generate-image-view-content-left-body'>
                                            {props.data[props.selectedPromptId].strength}
                                        </div>
                                        <div className='hr'></div>
                                    </>
                                )}

                                <div className='popup-generate-image-view-content-left-buttons'>
                                    <img src={downloadImg} alt='' style={{marginRight: '30px'}} onClick={() => handleDownload()}/>
                                    <img src={deleteImg} alt='' onClick={() => {
                                        props.setDeleteTarget(1)
                                        props.setDeleterPopup(true)
                                    }}/>
                                </div>

                                
                            </div>
                            
                        </div>
                        <div className='popup-generate-image-view-content-right'>
                            <div className='popup-generate-image-view-content-right-wrapper'>
                                <img
                                    src={`https://www.dandycv.com/media/${props.data[props.selectedPromptId].image_name_list[props.selectedImageId].image_name}`}
                                    alt=''
                                />
                            </div>
                            {(props.selectedImageId > 0 || props.selectedPromptId > 0) && (
                                <div className='popup-generate-image-view-content-right-slide-container' style={{left: '0'}} onClick={handleLeftArrowPress}>
                                    <img src={arrowImg} alt='' style={{transform: 'rotate(180deg)'}}/>
                                </div>
                            )}

                            {((props.selectedImageId !== props.data[props.selectedPromptId].image_name_list.length - 1) || (props.selectedPromptId !== props.data.length - 1)) && (
                                <div className='popup-generate-image-view-content-right-slide-container' style={{right: '0'}} onClick={handleRightArrowPress}>
                                    <img src={arrowImg} alt='' />
                                </div>
                            )}
                            
                            
                        </div>
                    </div>
                </div>
        </div>
      ) : "";
}

export default ViewPopup
