import React, { useState } from 'react';
import axios from 'axios';

import closeBtnImg from '../../images/close-btn.svg'

function CSVUploaderPopup(props) {
    const [csvFile, setCSVFile] = useState(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0]

        if (file.name.endsWith('.csv')) {
            setCSVFile(file)
        } else {
            setCSVFile(null)
        }
    };

    const handleSendFile = async (e) => {
        if (csvFile) {
            const formData = new FormData();
            formData.append("csv_file", csvFile);

            try {
                const response = await axios.post("https://www.dandycv.com/admin_generations/api/posts/from_csv/", formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                });

                if (response.data.data) {
                    setCSVFile(null)
                    props.setTrigger(false)
                } else {
                    alert(response.data.result);
                }
              } catch (error) {
                console.error("Error uploading file:", error);
              }
        } else {
            alert('please upload csv file');
        }
    }

    return (props.trigger) ? (
        <div className='popup-color-picker-overlay' onClick={() => props.setTrigger(false)}>
            <div className='popup-generate-image-view-content' style={{width: 300, height: 300}} onClick={(e) => {e.stopPropagation()}}>
                <div style={{marginLeft: 'auto', marginRight: '20px', marginTop: '11px'}}>
                    <img src={closeBtnImg} alt='' style={{cursor: 'pointer'}} onClick={() => props.setTrigger(false)}/>
                </div>

                <div style={{margin: '20px'}}>
                    <input type="file" onChange={handleFileChange} />
                    <button onClick={handleSendFile} style={{
                        marginBottom: '20px',
                        border: '1px solid #B4307D',
                        borderRadius: '5px',
                        width: '100px',
                        height: '25px',
                        color: '#B4307D',
                        background: '#FFF',
                        cursor: 'pointer'
                    }}>Upload</button>
                </div>
            </div>
        </div>
    ) : "";
}

export default CSVUploaderPopup
